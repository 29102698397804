import React, { Component } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import { Layout } from 'antd';
import Header from '../../components/Head';
import Sider from '../../components/Left';
import Login from '../Login';
import './index.css';

const { Content } = Layout;

export default class Home extends Component {
	state = { collapsed: false };

	toggle = () => {
		this.setState({
			collapsed: !this.state.collapsed,
		});
	};

	render() {
		const { collapsed } = this.state;
		return (
			<Layout style={{ height: '100%' }}>
				<Sider collapsed={collapsed} toggle={this.toggle} />
				<Layout className='site-layout'>
					<Header collapsed={collapsed} toggle={this.toggle} />
					<Content
						className='site-layout-background'
						style={{
							margin: '24px 16px',
							padding: 24,
							minHeight: 280,
						}}>
						<Switch>
							{/* <Redirect from="/" exact to="/home" />
							<Route path="/home" component={Home} /> */}
							<Route path='/login' component={Login} />
						</Switch>
					</Content>
				</Layout>
			</Layout>
		);
	}
}
