import React, { Component } from 'react';

export default class Login extends Component {
	render() {
		return (
			<div>
				<h2>我是Login页面</h2>
			</div>
		);
	}
}
