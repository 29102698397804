import React, { Component } from 'react';
import './App.css';
import Home from './pages/Home';

export default class App extends Component {
	render() {
		return (
			<div style={{ height: '100%' }}>
				<Home />
			</div>
		);
	}
}
